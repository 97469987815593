// PERMISOS
export const CREATE_DAC_ROLE = "CREATE_DAC_ROLE";
export const CREATE_CAMPAIGN_ROLE = "CREATE_CAMPAIGN_ROLE";
export const CREATE_MILESTONE_ROLE = "CREATE_MILESTONE_ROLE";
export const REVIEW_CAMPAIGN_ROLE = "REVIEW_CAMPAIGN_ROLE";
export const REVIEW_MILESTONE_ROLE = "REVIEW_MILESTONE_ROLE";
export const EXCHANGE_RATE_ROLE = "EXCHANGE_RATE_ROLE";
export const TRANSFER_ROLE = "TRANSFER_ROLE";

// GRUPOS
//TODO: Revisar como establecer relaciones entre los anteriores que se usan en el
//smart contract y los establecidos a nivel de documentacion
export const GIVER_ROLE = "GIVER_ROLE";
export const DELEGATE_ROLE = "DELEGATE_ROLE";
export const CAMPAIGN_MANAGER_ROLE = "CAMPAIGN_MANAGER_ROLE";
export const CAMPAIGN_REVIEWER_ROLE = "CAMPAIGN_REVIEWER_ROLE";
export const MILESTONE_MANAGER_ROLE = "MILESTONE_MANAGER_ROLE";
export const MILESTONE_REVIEWER_ROLE = "MILESTONE_REVIEWER_ROLE";
export const RECIPIENT_ROLE = "RECIPIENT_ROLE";

export const ALL_ROLES = [
    CREATE_DAC_ROLE,
    CREATE_CAMPAIGN_ROLE,
    CREATE_MILESTONE_ROLE,
    REVIEW_CAMPAIGN_ROLE,
    REVIEW_MILESTONE_ROLE,
    EXCHANGE_RATE_ROLE,
    TRANSFER_ROLE,

    // Grupos
    GIVER_ROLE,
    DELEGATE_ROLE,
    CAMPAIGN_MANAGER_ROLE,
    CAMPAIGN_REVIEWER_ROLE,
    MILESTONE_MANAGER_ROLE,
    MILESTONE_REVIEWER_ROLE,
    RECIPIENT_ROLE
]
 

