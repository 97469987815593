import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators'
import avalService from 'services/AvalService';
import avaldaoContractApi from '../../lib/blockchain/AvaldaoContractApi';

export const fetchAvalesOnChainEpic = action$ => action$.pipe(
  ofType('avales/fetchAvalesOnChain'),
  mergeMap(action => avalService.getAvalesOnChain()),
  map(avales => ({
    type: 'avales/mergeAvales',
    payload: avales
  }))
)

export const fetchAvalByIdEpic = action$ => action$.pipe(
  ofType('avales/fetchAvalById'),
  mergeMap(action => avalService.getAvalById(action.payload)),
  map(avales => ({
    type: 'avales/updateAvalById',
    payload: avales
  }))
)

export const fetchAvalesOffChainEpic = action$ => action$.pipe(
  ofType('avales/fetchAvalesOffChain'),
  mergeMap(action => avalService.getAvalesOffChain()),
  map(avales => ({
    type: 'avales/mergeAvales',
    payload: avales
  }))
)

export const completarAvalEpic = action$ => action$.pipe(
  ofType('avales/completarAval'),
  mergeMap(action => avalService.completarAval(action.payload)),
  map(aval => ({
    type: 'avales/updateAvalById',
    payload: aval
  }))
)

export const firmarAvalEpic = action$ => action$.pipe(
  ofType('avales/firmarAval'),
  mergeMap(action => avalService.firmarAval(
    action.payload.aval,
    action.payload.signerAddress
  )),
  map(aval => ({
    type: 'avales/updateAvalById',
    payload: aval
  }))
)